(function(window) { var svs = window.svs || {}; svs.modules = svs.modules || []; if(svs.modules.indexOf('/modules/marketplace/components/retail-list-page/assets/javascripts/retail-list-page.js') >= 0) return;  svs.modules.push('/modules/marketplace/components/retail-list-page/assets/javascripts/retail-list-page.js');

'use strict';

const {
  WidgetArea
} = svs.components.lbUtils.widgetArea;
const {
  GameList
} = svs.components.marketplace.gameList;
const {
  RetailList
} = svs.components.marketplace.retailList;
const {
  useEffect,
  useRef,
  useCallback
} = React;
const {
  useDispatch,
  useSelector
} = ReactRedux;
const {
  selectors
} = svs.components.marketplaceData;
const {
  groupTypeNames
} = svs.components.marketplaceData.constants;
const {
  fetchNextRetailersAndTeamsAction
} = svs.components.marketplaceData.teams.actions;
const groupTypeFilter = groupTypeNames.RETAILER;
const {
  initStates
} = svs.components.marketplaceData.constants;
const RetailListPage = () => {
  const dispatch = useDispatch();
  const loadDataRef = useRef(null);
  const intersectionObserver = useRef(null);
  const timeoutId = useRef(null);
  const {
    hasMoreData,
    nextLoading,
    loading,
    data
  } = useSelector(selectors.teams.getRetailerTeamsResult);
  const init = useRef(initStates.WAITING);
  const offset = useSelector(selectors.teams.selectRetailerTeamsOffset);
  const handleIntersect = useCallback(entries => {
    entries.forEach(entry => {
      const isIntersectingLoadDataRef = entry.target === loadDataRef.current && entry.isIntersecting;
      if (isIntersectingLoadDataRef && !nextLoading && hasMoreData) {
        intersectionObserver.current.unobserve(loadDataRef.current);
        dispatch(fetchNextRetailersAndTeamsAction({
          groupTypeFilter,
          offset
        }));
      }
    });
  }, [dispatch, hasMoreData, nextLoading]);
  useEffect(() => {
    if (init.current === initStates.WAITING) {
      init.current = initStates.ACTIVE;
      const onFetchResults = () => dispatch(fetchNextRetailersAndTeamsAction({
        groupTypeFilter,
        offset
      }));
      if (!(data !== null && data !== void 0 && data.length)) {
        onFetchResults();
      }
    } else if (init.current === initStates.ACTIVE && loading) {
      init.current = initStates.COMPLETE;
    } else if (init.current === initStates.COMPLETE && !loading) {
      init.current = initStates.READY;
    }
  }, [loading, data, dispatch]);
  useEffect(() => {
    intersectionObserver.current = new IntersectionObserver(handleIntersect, {
      root: null,
      threshold: [0, 0.5]
    });
    timeoutId.current = setTimeout(() => {
      loadDataRef.current && intersectionObserver.current.observe(loadDataRef.current);
    }, 200);
    return () => {
      if (intersectionObserver.current) {
        intersectionObserver.current.disconnect();
      }
      if (timeoutId.current) {
        clearTimeout(timeoutId.current);
        timeoutId.current = null;
      }
    };
  }, [handleIntersect, data]);
  return React.createElement("div", {
    className: "marketplace-full-width"
  }, React.createElement("div", {
    className: "marketplace-width-limiter"
  }, React.createElement("div", {
    className: "retail-list",
    "data-testid": "qa-mkp-retail-teamlist"
  }, React.createElement(RetailList, {
    history: history,
    isHeaderInverted: true,
    maxGamesToDisplay: 3,
    shouldUseLastClicked: true,
    teamClassName: "retail-listing margin-1"
  }, team => React.createElement(GameList, {
    className: "retail-game-list",
    fromTeamList: true,
    gameLinkSearch: "returnUrl=/ombudslag",
    groupId: team.id,
    hasBottomBorder: true,
    isRetail: true,
    maxGamesToDisplay: 3,
    noFetch: true
  }))), React.createElement("div", {
    className: "qa-fetch-more-data",
    ref: loadDataRef
  }, React.createElement("div", {
    className: "align-center"
  }, React.createElement("div", {
    className: "padding-1"
  }, hasMoreData && !loading ? 'Visar mer...' : '')))), React.createElement("div", {
    className: "marketplace-content-wrapper"
  }, React.createElement(WidgetArea, {
    className: "marketplace-retail-widget-area",
    widgetArea: "retailWidgetArea"
  })));
};
setGlobal('svs.marketplace.components.retailListPage.RetailListPage', RetailListPage);

 })(window);