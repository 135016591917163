(function(window) { var svs = window.svs || {}; svs.modules = svs.modules || []; if(svs.modules.indexOf('/modules/marketplace/components/retail-list-page/assets/javascripts/retail-list-onboarding.js') >= 0) return;  svs.modules.push('/modules/marketplace/components/retail-list-page/assets/javascripts/retail-list-onboarding.js');
'use strict';

const {
  RetailOnboarding,
  GameSelector
} = svs.marketplace.components.common;
const {
  WidgetArea
} = svs.components.lbUtils.widgetArea;
const RetailListOnboarding = () => React.createElement("div", {
  className: "bg-sport-50 marketplace-full-width padding-bottom-2",
  "data-testid": "qa-mkp-retail-welcome"
}, React.createElement(WidgetArea, {
  className: "marketplace-widget",
  widgetArea: "retailWidgetAreaTop"
}), React.createElement("div", {
  className: "marketplace-content-wrapper padding-top-2"
}, React.createElement(RetailOnboarding, null), React.createElement(GameSelector, null), React.createElement(WidgetArea, {
  className: "marketplace-retail-widget-area",
  widgetArea: "retailWidgetArea"
})));
setGlobal('svs.marketplace.components.retailListPage.RetailListOnboarding', RetailListOnboarding);

 })(window);